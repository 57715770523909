/* SWUP */
import Swup from 'swup';
import SwupProgressPlugin from '@swup/progress-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupGtmPlugin from '@swup/gtm-plugin';

const options = {
  linkSelector: 'a[href^="' +
    window.location.origin +
    '"]:not([data-no-swup]), ' +
    'a[href^="/"]:not([data-no-swup])',
  plugins: [
    new SwupProgressPlugin(),
    new SwupScrollPlugin(),
    new SwupGtmPlugin()
  ]
}

const swup = new Swup(options);

// swup.on('contentReplaced', () => {
//   console.log('Swup content replaced!')
// })

// swup.on('enabled', () => {
//   console.log('Swup enabled!')
// })
