import "./swup.js"

const root = document.documentElement;
const offsetStick = 30

// Get the navbar
const header = document.getElementById("header")

console.log('header height', header.offsetHeight);

function _checkSticky(e) {
  if (window.pageYOffset > offsetStick) {
    header.classList.add("sticky")
  } else {
    header.classList.remove("sticky")
  }
}

// When the user scrolls the page, execute myFunction
window.onscroll = _checkSticky

setTimeout(() => {
  _checkSticky();
}, 500)

// Mouse pointer over CSS.
// @url: https://codepen.io/bramus/pen/eBZgPB
document.addEventListener('mousemove', evt => {
    let x = evt.clientX / innerWidth;
    let y = evt.clientY / innerHeight;
 
    root.style.setProperty('--mouse-x', x);
    root.style.setProperty('--mouse-y', y);
});

document.querySelectorAll('.highlight, .button').forEach((el) => {
    el.addEventListener("mousemove", function(e) {
      const { x, y, width, height } = this.getBoundingClientRect();
      const _x = (e.clientX - x) / width;
      const _y = (e.clientY - y) / height;
      this.style.setProperty("--x", _x);
      this.style.setProperty("--y", _y);
      this.style.setProperty("--x-center", _x * 2 - 1);
      this.style.setProperty("--y-center", _y * 2 - 1);
      this.style.setProperty("--width", width);
      this.style.setProperty("--height", height);
    });
});

// transform: perspective(1000px) rotateX(calc(var(--y-center)*(-1deg))) rotateY(calc(var(--x-center)*(1deg)))